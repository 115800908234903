// React import 
import React from 'react';
// Additional libraries 

import { connect } from 'react-redux';
// import store from 'redux/store';
import AgentsInChat from './AgentsInChat';


class ChatDetails extends React.Component{
    
    render(){
        return (
            <AgentsInChat />
        )
    }

}

const mapStateToProps = (state) => {
    const {displaySettings, agentTableMain} = state.present;
    const navbar = {displaySettings}
    return {
        navbar
	};
}

export default connect(mapStateToProps)(ChatDetails)


// React import 
import React from 'react';
// Additional libraries 

class SingleTd extends React.Component{

    shouldComponentUpdate = (nextProps) => {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props);
    }

    generateMessage = ({
        style,
        text
    }) => {
        return <div><span style={style}>{text}</span></div>;
    }

    render(){
        const {onClick, keyName, style, text, onMouseDown, onMouseOver, message, extraClass, colSpan} = this.props;
        return (
            <td 
                className={extraClass}
                onClick={onClick} 
                key={keyName} 
                style={style}
                onMouseDown={onMouseDown}
                onMouseOver={onMouseOver}
                colSpan={colSpan}
            > {message && this.generateMessage(message)} </td>
        )
    }

}

export default SingleTd
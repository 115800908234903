import React from "react";
import "./Navbar.scss";


class Navbar extends React.Component {

    render() {

        return(
            <div className="c-navbar">Chronos Scheduler Beta v0.0.1</div>
        );
    }
}

export default Navbar;
import React from "react";
import "./Control.scss";
import DatePicker from 'react-date-picker';




class Control extends React.Component {
    state = {
        dayType: "",
        value: new Date()
    }

    onDateChange = (newValue) => {
        this.setState({
            value: newValue
        })
    }

    render() {

        return(
            <div className="c-control">
                <DatePicker
                    onChange={this.onDateChange}
                    value={this.state.value}
                />
            </div>
        );
    }
}

export default Control;
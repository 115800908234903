const hours = [
    {"text": "Avengers ↓"},
    {"text": "00:00"},
    {"text": "01:00"},
    {"text": "02:00"},
    {"text": "02:30"},
    {"text": "03:00"},
    {"text": "03:30"},
    {"text": "04:00"},
    {"text": "04:30"},
    {"text": "05:00"},
    {"text": "05:30"},
    {"text": "06:00"},
    {"text": "06:30"},
    {"text": "07:00"},
    {"text": "07:30"},
    {"text": "08:00"},
    {"text": "08:30"},
    {"text": "09:00"},
    {"text": "09:30"},
    {"text": "10:00"},
    {"text": "10:30"},
    {"text": "11:00"},
    {"text": "11:30"},
    {"text": "12:00"},
    {"text": "12:30"},
    {"text": "13:00"},
    {"text": "13:30"},
    {"text": "14:00"},
    {"text": "14:30"},
    {"text": "15:00"},
    {"text": "15:30"},
    {"text": "16:00"},
    {"text": "16:30"},
    {"text": "17:00"},
    {"text": "17:30"},
    {"text": "18:00"},
    {"text": "18:30"},
    {"text": "19:00"},
    {"text": "19:30"},
    {"text": "20:00"},
    {"text": "20:30"},
    {"text": "21:00"},
    {"text": "21:30"},
    {"text": "22:00"},
    {"text": "22:30"},
    {"text": "23:00"},
    {"text": "23:30"},
    {"text": "24:00"},  
]

export default hours
const _ = require('lodash');
class Utils {
	static normalize = (val, max, min) => (val - min) / (max - min);

	static filterArrayOfObj = (array) => {
		const result = [];
		const map = [];
		for (const item of array) {
			if (!map.includes(item.data.pos)) {
				map.push(item.data.pos);
				result.push(item);
			}
		}
		return result;
	};

	static generateHoursTemplate = (hours) => {
		return hours.filter((a, i) => i !== 0).map((h, i) => {
			let duration = 30;
			return {
				hour: h.text,
				duration,
				timestamp: TimeUtils.setTimeFromObject(TimeUtils.getHoursFromText(h.text)),
				task: { color: '#000', working: false, task: 'none' },
			};
		});
	};

	static addOrRemoveFromArray(array, value) {
		var index = array.indexOf(value);
	
		if (index === -1) {
			array.push(value);
		} else {
			array.splice(index, 1);
		}
	}
}

class TimeUtils {
	static setTimeFromObject = (obj) => {
		let updatedDate = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Prague' }));
		updatedDate.setHours(parseInt(obj.hours));
		updatedDate.setMinutes(parseInt(obj.minutes));
		updatedDate.setSeconds(0);
		updatedDate.setMilliseconds(0);
		return updatedDate;
	};

	static getHoursFromText = (text, isLast) => {
		let hours = text.split(':')[0];
		let minutes = text.split(':')[1];
		if (isLast) {
			return {
				hours: parseInt(23),
				minutes: parseInt(59),
			};
		}
		return {
			hours: parseInt(hours),
			minutes: parseInt(minutes),
		};
	};

	static findDifferenceInMinutesBetweenTwoDates = (start, end, negative = false) => {
		if (!negative) {
			return Math.floor(Math.abs(end - start) / 1000 / 60);
		}
		else {
			return Math.floor((end - start) / 1000 / 60);
		}
	};

	static addMinutes (date, minutes) {
		return new Date(date.getTime() + minutes * 60000);
	}
}

class AgentUtils {
	static fillChatHours = ({ shift, hours }) => {
		const hourTemplate = Utils.generateHoursTemplate(hours);

		return hourTemplate.map((template, i) => {
			if (
				shift.worksToday &&
				this.checkIfShiftStarted({
					date: template.hour,
					shift,
					timeIndex: i + 1,
				})
			) {
				return {
					...template,
					task: { color: '#fff', task: 'none', working: true },
				};
			}
			else {
				return template;
			}
		});
	};

	static checkIfShiftStarted ({ date, shift, timeIndex }) {
		const { today, yesterday } = shift;

		let shiftStarted = false;

		let currentHour = TimeUtils.setTimeFromObject(TimeUtils.getHoursFromText(date));

		let yesterdayShift = TimeUtils.setTimeFromObject(TimeUtils.getHoursFromText('17:00'));
		yesterdayShift.setDate(yesterdayShift.getDate() - 1);

		//Check the schedule is from yesterday
		if (currentHour.getHours() <= 8 && new Date(yesterday.start.timestamp) >= yesterdayShift && timeIndex < 14) {
			if (new Date(yesterday.start.timestamp) <= currentHour && new Date(yesterday.end.timestamp) > currentHour)
				shiftStarted = true;
		}
		else {
			if (new Date(today.start.timestamp) <= currentHour && new Date(today.end.timestamp) > currentHour)
				shiftStarted = true;
		}
		return shiftStarted;
	}

	static extendLiveChatTo = (agent, hour) => {
		// Find if the extension has to be forwards or backwards

		let startTime = new Date(this.getChatStartTask(agent).timestamp);
		let endTime = TimeUtils.addMinutes(new Date(this.getChatStartTask(agent).timestamp), 30).toString();
		let taskList = [];
		if (hour > startTime) {
			taskList = this.getAllTaskBetweenTimestamps(agent, endTime, hour);
		}
		else {
			taskList = this.getAllTaskBetweenTimestamps(agent, hour, startTime);
		}

		taskList.forEach((task) => {
			task.task[0].color = '#FFFF00';
			task.task[0].task = 'chats';
			task.task[1].color = '#00ffff';
			task.task[1].task = 'assigning';
		});
	};

	static getAllTaskBetweenTimestamps = (agent, start, end) => {
		return agent.task.filter((t) => {
			return new Date(t.timestamp) >= new Date(start) && new Date(t.timestamp) <= new Date(end);
		});
	};

	static getChatStartTask = (agent) => {
		return agent.task.find((t) => t.task[0].task === 'chats');
	};

	static getChatEndTask = (agent) => {
		return agent.task.slice().reverse().find((t) => t.task[0].task === 'chats');
	};

	static findTotalTimeInChatsInMinutes = (agent) => {
		let total = 0;
		agent.task.forEach((task) => {
			if (task.task[0].task === 'chats') total += task.duration;
		});
		return total;
	};

	static paintAllAdjacentTask = (taskList, index, selectedTool, secondaryTask = false) => {
		let previousTaskIndex = index;
		let nextTaskIndex = index;

		while (
			taskList[previousTaskIndex - 1] &&
			taskList[previousTaskIndex - 1].task.working &&
			taskList[previousTaskIndex - 1].task.color === taskList[index].task.color
		) {
			taskList[previousTaskIndex - 1]['task']['color'] = selectedTool[0].color;
			taskList[previousTaskIndex - 1]['task']['task'] = selectedTool[0].task;
			taskList[previousTaskIndex - 1]['task']['text'] = selectedTool[0].text || '';

			if (
				secondaryTask &&
				secondaryTask[previousTaskIndex - 1] &&
				secondaryTask[previousTaskIndex - 1].task.color === '#fff' &&
				secondaryTask[previousTaskIndex - 1].task.color !== selectedTool[0].color
			)
			{
				secondaryTask[previousTaskIndex - 1]['task']['color'] = selectedTool[1].color;
				secondaryTask[previousTaskIndex - 1]['task']['task'] = selectedTool[1].task;
				secondaryTask[previousTaskIndex - 1]['task']['text'] = selectedTool[1].text || '';
			}
				previousTaskIndex--;
		}

		while (
			taskList[nextTaskIndex + 1] &&
			taskList[nextTaskIndex + 1].task.working &&
			taskList[nextTaskIndex + 1].task.color === taskList[index].task.color
		) {
			taskList[nextTaskIndex + 1]['task']['color'] = selectedTool[0].color;
			taskList[nextTaskIndex + 1]['task']['task'] = selectedTool[0].task;
			taskList[nextTaskIndex + 1]['task']['text'] = selectedTool[0].text || '';

			if (
				secondaryTask &&
				secondaryTask[nextTaskIndex + 1] &&
				secondaryTask[nextTaskIndex + 1].task.color === '#fff' &&
				secondaryTask[nextTaskIndex + 1].task.color !== selectedTool[0].color
			)
			{
				secondaryTask[nextTaskIndex + 1]['task']['color'] = selectedTool[1].color;
				secondaryTask[nextTaskIndex + 1]['task']['task'] = selectedTool[1].task;
				secondaryTask[nextTaskIndex + 1]['task']['text'] = selectedTool[1].text || '';
			}


			nextTaskIndex++;
		}
		if(taskList[index]['task'].working){
			taskList[index]['task']['color'] = selectedTool[0].color;
			taskList[index]['task']['task'] = selectedTool[0].task;
			taskList[index]['task']['text'] = selectedTool[0].text || '';
		}
	

		if (
			secondaryTask &&
			secondaryTask[index] &&
			secondaryTask[index]['task'].working &&
			secondaryTask[index].task.color === '#fff' &&
			secondaryTask[index].task.color !== selectedTool[0].color
		)
		{
			secondaryTask[index]['task']['color'] = selectedTool[1].color;
			secondaryTask[index]['task']['task'] = selectedTool[1].task;
			secondaryTask[index]['task']['text'] = selectedTool[1].text || '';
		}

		return {
			taskList,
			secondaryTask
		};
	};

	static paintAllOppositeAdjacentTask = (agentTableMain ,secondaryTask , selected ,index, selectedTool) => {
		let previousTaskIndex = index;
		let nextTaskIndex = index;
		let taskList = selected === 'agentTableMain' ? agentTableMain : secondaryTask;
		let opposite = selected === 'agentTableMain' ? secondaryTask : agentTableMain;

		while (
			opposite[previousTaskIndex - 1] &&
			opposite[previousTaskIndex - 1].task.working &&
			opposite[previousTaskIndex - 1].task.color === opposite[index].task.color
		) {

			if (
				taskList &&
				taskList[previousTaskIndex - 1] &&
				taskList[previousTaskIndex - 1].task.color !== selectedTool[0].color
			)
			{
				taskList[previousTaskIndex - 1]['task']['color'] = selectedTool[0].color;
				taskList[previousTaskIndex - 1]['task']['task'] = selectedTool[0].task;
				taskList[previousTaskIndex - 1]['task']['text'] = selectedTool[0].text || '';
			}
				previousTaskIndex--;
		}

		while (
			opposite[nextTaskIndex + 1] &&
			opposite[nextTaskIndex + 1].task.working &&
			opposite[nextTaskIndex + 1].task.color === opposite[index].task.color
		) {
			

			if (
				taskList &&
				taskList[nextTaskIndex + 1] &&
				taskList[nextTaskIndex + 1].task.color !== selectedTool[0].color
			)
			{
				taskList[nextTaskIndex + 1]['task']['color'] = selectedTool[0].color;
				taskList[nextTaskIndex + 1]['task']['task'] = selectedTool[0].task;
				taskList[nextTaskIndex + 1]['task']['text'] = selectedTool[0].text || '';
			}


			nextTaskIndex++;
		}
		
		if (
			taskList &&
			taskList[index] &&
			taskList[index]['task'].working &&
			taskList[index].task.color !== selectedTool[0].color
		)
		{
			taskList[index]['task']['color'] = selectedTool[0].color;
			taskList[index]['task']['task'] = selectedTool[0].task;
			taskList[index]['task']['text'] = selectedTool[0].text || '';
		}

		return {
			agentTableMain,
			secondaryTask
		};
	};


}

class ChatUtils {
	static findWorkingAgents (date, agentList) {
		return agentList.filter((agent) => {
			let todayStart = agent.data.today.start.timestamp;
			let todayEnd = agent.data.today.end.timestamp;
			return new Date(todayStart) <= date && new Date(todayEnd) > date;
		});
	}

	static findAgentsWithLessThanMaxLiveChatHoursDoneToday = (agents, maxTime) => {
		return agents
			.filter((agent) => {
				return AgentUtils.findTotalTimeInChatsInMinutes(agent) < maxTime * 60 - 30;
			})
			.sort((a, b) => {
				return AgentUtils.findTotalTimeInChatsInMinutes(a) - AgentUtils.findTotalTimeInChatsInMinutes(b);
			});
	};

	static findTargetAmountOfAgentsInChat = (time, expectedAgentsPerHour) => {
		const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Prague' }));
		if (time.getHours() >= 23 && time.getMinutes() >= 30) return expectedAgentsPerHour[0];

		return expectedAgentsPerHour.filter((timeData) => {
			let start = TimeUtils.setTimeFromObject(timeData.start);
			let end = TimeUtils.setTimeFromObject(timeData.end);
			return time >= start && time <= end;
		})[0];
	};

	static findAgentsThatAreInLiveChat = (time, agentList = []) => {
		return agentList.filter((agent) => {
			return (
				agent.task.filter((t) => {
					return new Date(t.timestamp).getTime() === time.getTime() && t.task[0].task === 'chats';
				}).length > 0
			);
		});
	};
}

export { AgentUtils };

import Control from "./Control";
import Navbar from "./Navbar";
import Grid from './Grid';
import React from 'react'
import './App.css';

import { Provider } from 'react-redux'
import store from './redux/store';


class App extends React.Component {
    state = {
        dayType: ""
    }

    updateDayType = (dayType) => {
        console.log('test', dayType);
        this.setState({
            ...this.state,
            dayType
        })
    }

    render() {
        return (
            <Provider store={store}>
                <div className="App">
                    <header className="App-header">
                        <Navbar />
                        <Control callback={this.updateDayType} />
                        <Grid dayType={this.state.dayType} />
                    </header>
                </div>
            </Provider>
        )
    }
}

export default App;

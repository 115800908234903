const userSettings = {
    "Lukáš Vacek":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":1,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ffff00",
        "img": "Vacek_Lukas_305.jpg"
    },
    "Blas Palencia":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":1.5,
                "maxLiveChatTime":3,
                "liveChatFactor":1
            }
        },
        "showSurname":false,
        "pos":2,
        "role":"support",
        "lang":[
            "ES",
            "EN",
            "IT",
            "PT"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#8e7cc3",
        "img":"Palencia_Blas_206.jpg"
    },
    "Nikolai Zveznet":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":3,
        "role":"support",
        "lang":[
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ea9999",
        "img":"niko.JPG"
    },
    "Honza Vysušil":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":4,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "img": "Vysusil.jpg",
        "color":"#00ffff"
    },
    "Marcin Mączewski":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1,
                "targetLiveChatTime":1.5,
                "maxLiveChatTime":4,
                "liveChatFactor":20
            }
        },
        "showSurname":false,
        "pos":5,
        "role":"support",
        "lang":[
            "PL",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff9900",
        "img":"maczewski.JPG"
    },
    "Sarah Kapodistria":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1,
                "targetLiveChatTime":1.5,
                "maxLiveChatTime":4,
                "liveChatFactor":20
            }
        },
        "showSurname":false,
        "pos":6,
        "role":"support",
        "lang":[
            "DE",
            "EN",
            "GR"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff00ff",
        "img":"Sarah.JPG"
    },
    "Peter Mencák":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":4,
                "liveChatFactor":60
            }
        },
        "showSurname":false,
        "pos":8,
        "role":"support",
        "lang":[
            "CZ",
            "DE",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ffff00",
        "img":"mencak.JPG"
    },
    "Bart Skonieczka":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":4,
                "liveChatFactor":70
            }
        },
        "showSurname":false,
        "pos":7,
        "role":"support",
        "lang":[
            "PL",
            "EN",
            "CZ"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ff00",
        "img":"bart.JPG"
    },
    "Douglas Whyte":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":4,
                "liveChatFactor":50
            }
        },
        "showSurname":false,
        "pos":9,
        "role":"support",
        "lang":[
            "EN",
            "FR"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#b4a7d6",
        "img":"whyte.JPG"
    },
    "Marina Curzi":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":4,
                "liveChatFactor":40
            }
        },
        "showSurname":false,
        "pos":10,
        "role":"support",
        "lang":[
            "PT",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff00ff",
        "img":"Renault.JPG"
    },
    "Filip Misiło":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":11,
        "role":"support",
        "lang":[
            "PL",
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#00ff00",
        "img":"misilo.JPG"
    },
    "Marek Míček":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":true,
        "pos":12,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ffff00",
        "img":"micek.JPG"
    },
    "William Holm":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":4,
                "liveChatFactor":30
            }
        },
        "showSurname":false,
        "pos":13,
        "role":"support",
        "lang":[
            "EN",
            "NO",
            "DK",
            "SWE"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#00ff00",
        "img":"holm.JPG"
    },
    "Michael Kuznetsov":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":14,
        "role":"support",
        "lang":[
            "RU",
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff9900",
        "img":"kuzni.JPG"
    },
    "Jakub Kučera":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":true,
        "pos":15,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ffff",
        "img":"Kucera.JPG"
    },
    "Ondřej Plocica":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":16,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ff00",
        "img":"Plocica.JPG"
    },
    "Adam Rippl":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":true,
        "pos":17,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff9900",
        "img":"rippl.JPG"
    },
    "Šimon Doubek":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":18,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ff00",
        "img":"doubek.JPG"
    },
    "Marc Martínez Badenes":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":6,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":19,
        "role":"support",
        "lang":[
            "ES",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ffff",
        "img":"Martinez.JPG"
    },
    "Michaela Vejvodová":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":4,
                "liveChatFactor":40
            }
        },
        "showSurname":false,
        "pos":20,
        "role":"support",
        "lang":[
            "CZ",
            "DE",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff00ff",
        "img":"Vejvodova.JPG"
    },
    "Alessandro Pantaleo":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":4,
                "liveChatFactor":80
            }
        },
        "showSurname":false,
        "pos":21,
        "role":"support",
        "lang":[
            "IT",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff9900",
        "img":"pantalleo.JPG"
    },
    "Tommy Muszynski":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":22,
        "role":"support",
        "lang":[
            "EN",
            "Afr",
            "PL"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ffff00",
        "img":"tommy.JPG"
    },
    "Giuliano Dipoppa":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":23,
        "role":"support",
        "lang":[
            "IT",
            "EN",
            "DE",
            "ES"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ffff",
        "img":"dipoppa.JPG"
    },
    "Georgios Psoromytis":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":24,
        "role":"support",
        "lang":[
            "EN",
            "GR"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ff00",
        "img":"psoromytis.JPG"
    },
    "Stevan Sindelic":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":25,
        "role":"support",
        "lang":[
            "EN",
            "SRB"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff9900",
        "img":"sindelic.JPG"
    },
    "Robby Gernaerd":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":26,
        "role":"support",
        "lang":[
            "EN",
            "NL"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ffff00",
        "img":"robby.JPG"
    },
    "Marek Varmuža":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":true,
        "pos":27,
        "role":"support",
        "lang":[
            "CZ",
            "EN",
            "FR"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ffff",
        "img":"varmuza.JPG"
    },
    "Ithan Lara Fernandez":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":28,
        "role":"support",
        "lang":[
            "ES",
            "EN",
            "Cat"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#00ff00",
        "img":"ithan.JPG"
    },
    "Pedro Regadas":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":29,
        "role":"support",
        "lang":[
            "PT",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ffff00",
        "img":"pedro.JPG"
    },
    "Michele Moramarco":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":30,
        "role":"support",
        "lang":[
            "IT",
            "EN",
            "ES"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#6d9eeb",
        "img":"moramarco.JPG"
    },
    "Anabella Ojeda":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":31,
        "role":"support",
        "lang":[
            "ES",
            "IT",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff00ff",
        "img":"Ojeda.png"
    },
    "Tomáš Ridzák":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":32,
        "role":"support",
        "lang":[
            "CZ",
            "FR",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ff00",
        "img":"ridzak.JPG"
    },
    "Rodolfo Padilla Ruiz":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":33,
        "role":"support",
        "lang":[
            "ES",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#b4a7d6",
        "img":null
    },
    "Keno Beck":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":34,
        "role":"support",
        "lang":[
            "DE",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ffff",
        "img":"keno.jpg"
    },
    "Adam Šitanc":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":true,
        "pos":35,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#6d9eeb",
        "img":"Sitanc.png"
    },
    "Viktor Olijnyk":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":36,
        "role":"support",
        "lang":[
            "CZ",
            "EN",
            "RU"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff9900",
        "img":"olijnyk.JPG"
    },
    "Štěpán Maxa":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":37,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ff00",
        "img":"brejle.png"
    },
    "Dominik Sumetsberger":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":38,
        "role":"support",
        "lang":[
            "DE",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ffff00",
        "img":"Sumetsberger.png"
    },
    "Robert Wünsche":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":39,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#ff9900",
        "img":"Wunsche.png"
    },
    "Ivan Godinez":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":1.5,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":5,
                "liveChatFactor":100
            }
        },
        "showSurname":false,
        "pos":40,
        "role":"support",
        "lang":[
            "EN",
            "ES"
        ],
        "phones":false,
        "task":[
            "lvc"
        ],
        "color":"#00ffff",
        "img":"godinez.png"
    },
    "Kaloyan Hristov":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":41,
        "role":"support",
        "lang":[
            "EN",
            "DE",
            "BG"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#00ff00",
        "img":"Pajskrova_1.png"
    },
    "Isabela Kvašňáková":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":42,
        "role":"support",
        "lang":[
            "CZ",
            "FR",
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ff00ff",
        "img":"Kvasnakova.png"
    },
    "Pája Šimůnková":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":43,
        "role":"support",
        "lang":[
            "CZ",
            "FR",
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ff00ff"
    },
    "Martin McNickle":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":44,
        "role":"support",
        "lang":[
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ff00ff"
    },
    "Romario Andres Solano":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":45,
        "role":"support",
        "lang":[
            "EN",
            "ES"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ff9900"
    },
    "Marek Nekola":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":true,
        "pos":46,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ffff00"
    },
    "Klára Sůvová":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":47,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ff00ff"
    },
    "Lecheng Zhang":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":47,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#F9CB9C"
    },
    "Kateřina Michálková":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":47,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ff00ff"
    },
    "James Dougherty":{
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":47,
        "role":"support",
        "lang":[
            "CZ",
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ffff00"
    },
    "Max Hollamby": {
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":47,
        "role":"support",
        "lang":[
            "EN"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ffff00"
    },
    "Peter Somi": {
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":47,
        "role":"support",
        "lang":[
            "EN", "HG", "SL"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ff00ff"
    },
    "Taras Smirnov": {
        "chatSettings":{
            "weekdays":{
                "minLiveChatTime":0,
                "targetLiveChatTime":3.5,
                "maxLiveChatTime":0,
                "liveChatFactor":0
            }
        },
        "showSurname":false,
        "pos":47,
        "role":"support",
        "lang":[
            "EN", "RU", "CZ"
        ],
        "phones":false,
        "task":[

        ],
        "color":"#ffff00"
    }
};

export default userSettings;

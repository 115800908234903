// React import 
import React from 'react';
// Additional libraries 
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
 
import { Provider } from 'react-redux'
import store from './../redux/store';
import DatePicker from 'react-date-picker';

// import Navbar from './Navbar'
import Grid from './grid/Grid'

// https://stackoverflow.com/questions/3224834/get-difference-between-2-dates-in-javascript
//
function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}


class Main extends React.Component{

    state = {
        showingMain : false,
        userSettings: false,
        chats: false,
        value: new Date(),
        days: 0
    }
    onDateChange = (newValue) => {
        /**
         * Get the difference in days between the current date and the selected date
         */
        const days = dateDiffInDays(new Date(), newValue);
        this.setState({
            value: newValue,
            days:  days
        })
        
    }


    getMaxDate = () => {
        const d = new Date();
        return new Date(new Date().setDate(d.getDate() + 5));
    }

    getMinDate = () => {
        const d = new Date();
        return new Date(new Date().setDate(d.getDate() - 3));
    }

    getListSelect =() => {
        return (
                <div className="text-center container mt-4">
                    <div className="row form-group">
                        <div className="col-md-6 text-right mb-2">
                        <DatePicker
                            className="form-control"
                            onChange={this.onDateChange}
                            value={this.state.value}
                            minDate={this.getMinDate()}
                         />
                        </div>
                        <div className="col-md-6">
                        <button onClick={() => {this.renderInfo(false)} }
                                className="btn btn-block border border-warning w-100 text-uppercase">
                            Fill black schedule
                        </button>
                        </div>
                    </div> 
                </div>
        )
    }

    renderInfo = (chats) => {
        this.setState({
            ...this.state,
            showingMain: true,
            chats
        })
    }


    render(){
        return (
            <Provider store={store}>
                {!this.state.showingMain && this.getListSelect()}
                {/* {!this.state.showingMain && <Settings></Settings>} */}
                {this.state.showingMain && <Grid chats={this.state.chats} days={this.state.days} />}
            </Provider>
        )
    }

}

export default Main
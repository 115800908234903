import React, { Component } from 'react';

import { connect } from 'react-redux';
import store from './../../../redux/store';
import NavButtons from './NavButtons';
import SelectedTool from './SelectedTool';
import Tools from './Tools';
import Hours from './Hours';
import ChatDetails from './chatDetails/ChatDetails';

class NavBar extends Component {
	
	

	render () {
		const { exportedVersion} = this.props;
		if(exportedVersion) return null;
		return (
			<div className="fixed-top">
				<nav className="navbar navbar-light bg-dark ">
					<a className="navbar-brand text-white">Chronos debug</a>
					<NavButtons />
				</nav>
				<Tools />
				<Hours />
				<ChatDetails />
			</div>
		);
	}
}

const mapStateToProps = (state) => {

	const {  displaySettings } = state.present;
	const {exportedVersion} = displaySettings;


	return {
		exportedVersion
	};
};
export default connect(mapStateToProps)(NavBar);

// React import
import React from 'react';
// Additional libraries

import { connect } from 'react-redux';

class Hours extends React.Component {
	generateHours = () => {
		return this.props.hours.map((h, index) => {
			return <div key={index}> {h.text} </div>;
		});
	};
	render () {
		return <div className="hours-grid">{this.generateHours()}</div>;
	}
}

const mapStateToProps = (state) => {
	const { hours } = state.present;
	return {
		hours,
	};
};
export default connect(mapStateToProps)(Hours);

// React import 
import React from 'react'
import { connect } from 'react-redux';
import { Button } from "react-bootstrap";
import store from './../../../redux/store';

const exportBtn = () => {
    store.dispatch({
        type: 'hookActionUpdateObject',
        payload: [{
            address: `displaySettings.exportedVersion`,
            value: true
        }]

        
    })
}

function NavButtons (props){
        return (
            <div>
                <Button onClick={exportBtn} variant="success">
                    Export
                </Button>
            </div>
        )
}

const mapStateToProps = (state) => {
    return {
    }
}
export default connect(mapStateToProps)(NavButtons)
const chatTimes = {
	weekdays: [
		{
			index: 1,
			start: {
				hours: '00',
				minutes: '00',
			},
			end: {
				hours: '1',
				minutes: '30',
			},
			min: 4,
			max: 4,
			czmin: 0,
		},
		{
			index: 2,
			start: {
				hours: '2',
				minutes: '00',
			},
			end: {
				hours: '3',
				minutes: '00',
			},
			min: 2,
			max: 3,
			czmin: 0,
		},
		{
			index: 3,
			start: {
				hours: '3',
				minutes: '30',
			},
			end: {
				hours: '6',
				minutes: '30',
			},
			min: 2,
			max: 2,
			czmin: 0,
		},
		{
			index: 4,
			start: {
				hours: '6',
				minutes: '30',
			},
			end: {
				hours: '7',
				minutes: '30',
			},
			min: 2,
			max: 2,
			czmin: 1,
		},
		{
			index: 5,
			start: {
				hours: '8',
				minutes: '00',
			},
			end: {
				hours: '09',
				minutes: '30',
			},
			min: 3,
			max: 3,
			czmin: 1,
		},
		{
			index: 6,
			start: {
				hours: '10',
				minutes: '00',
			},
			end: {
				hours: '16',
				minutes: '30',
			},
			min: 4,
			max: 4,
			czmin: 2,
		},
		{
			index: 7,
			start: {
				hours: '17',
				minutes: '00',
			},
			end: {
				hours: '23',
				minutes: '00',
			},
			min: 4,
			max: 5,
			czmin: 2,
		},
		{
			index: 8,
			start: {
				hours: '23',
				minutes: '30',
			},
			end: {
				hours: '24',
				minutes: '00',
			},
			min: 4,
			max: 4,
			czmin: 0,
		},
	],

	weekends: [
		{
			index: 1,
			start: {
				hours: '00',
				minutes: '00',
			},
			end: {
				hours: '2',
				minutes: '00',
			},
			min: 2,
			max: 3,
			czmin: 0,
		},
		{
			index: 2,
			start: {
				hours: '2',
				minutes: '00',
			},
			end: {
				hours: '3',
				minutes: '30',
			},
			min: 2,
			max: 2,
			czmin: 0,
		},
		{
			index: 3,
			start: {
				hours: '3',
				minutes: '30',
			},
			end: {
				hours: '6',
				minutes: '30',
			},
			min: 2,
			max: 2,
			czmin: 0,
		},
		{
			index: 4,
			start: {
				hours: '6',
				minutes: '30',
			},
			end: {
				hours: '8',
				minutes: '00',
			},
			min: 2,
			max: 2,
			czmin: 1,
		},
		{
			index: 5,
			start: {
				hours: '8',
				minutes: '00',
			},
			end: {
				hours: '10',
				minutes: '00',
			},
			min: 2,
			max: 3,
			czmin: 1,
		},
		{
			index: 6,
			start: {
				hours: '10',
				minutes: '00',
			},
			end: {
				hours: '17',
				minutes: '00',
			},
			min: 3,
			max: 3,
			czmin: 2,
		},
		{
			index: 7,
			start: {
				hours: '17',
				minutes: '00',
			},
			end: {
				hours: '23',
				minutes: '30',
			},
			min: 3,
			max: 4,
			czmin: 2,
		},
		{
			index: 8,
			start: {
				hours: '23',
				minutes: '30',
			},
			end: {
				hours: '24',
				minutes: '00',
			},
			min: 2,
			max: 3,
			czmin: 0,
		},
	],
};


export default chatTimes;
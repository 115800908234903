// React import 
import React from 'react';


import { connect } from 'react-redux';

class AgentsInChat extends React.Component{

    shouldComponentUpdate = (nextProps) => {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props);
    }

    generateAgentsIChats = () => {
        return null;
        return Object.keys(this.props.all).map( k => {
            return <div key={k}>{this.props.all[k]}</div>
        })
    }

    render(){
        return (
            <div className="hours-grid">{this.generateAgentsIChats()}</div>
        )
    }

}

const mapStateToProps = (state) => {
    const {agentsInChats} = state.present;
    const {all} = agentsInChats;

    
    return {
        all
    }
}
export default connect(mapStateToProps)(AgentsInChat)
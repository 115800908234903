// React import
import React from 'react';
// Additional libraries
import { connect } from 'react-redux';
import store from './../../../../redux/store';

import SingleTd from './SingleTd';
const default_first_column_width = 101.8;
class SecondaryTr extends React.Component {
	// shouldComponentUpdate = (nextProps) => {
	//     return JSON.stringify(nextProps) !== JSON.stringify(this.props);
	// }
	state = {
		dragging: false,
	};

    firstSelected = false;
    lastSelected = false;

      

	componentDidMount = () => {
		window.addEventListener('mouseup', this.handleMouseUp);
	};

	componentWillUnmount = () => {
		window.removeEventListener('mouseup', this.handleMouseUp);
	};

	handleMouseUp = () => {
		if (this.state.dragging) {
			this.setState({ dragging: false });
            // Recalculate stuff
            const {firstSelected, lastSelected} = this;
            const min = Math.min(firstSelected, lastSelected)
            const max = Math.max(firstSelected, lastSelected)
            store.dispatch({
            	type: 'updateMultipleTask',
            	payload: {
            		min,
            		max,
                    name: this.props.name,
            		main: this.props.table,
            	},
            });

            
		}
	};

	generateColspan = (taskList) => {

		let arr = [];
		let lasTask = false;
		let lasMainTrIndex = 0;

		taskList.forEach((details, index) => {
			const task = details.task;
			if (task.task === 'none' || task.color === '#000') {
				lasTask = task.task;
				lasMainTrIndex = index;
				arr.push({ render: true, colspan: 1, t: task.task, uniqueClass: `${this.props.name}-${lasMainTrIndex}` });
				return;
			}
			if (task.task === lasTask) {
				arr[lasMainTrIndex].colspan++;
				arr.push({ render: false, colspan: 1, uniqueClass: `${this.props.name}-${lasMainTrIndex}` });
				return;
			}
			else {
				lasTask = task.task;
				lasMainTrIndex = index;
				let text = task.text;

				arr.push({ render: true, colspan: 1, text, uniqueClass: `${this.props.name}-${lasMainTrIndex}` });
				return;
			}
		});
		return arr;
	};

	getNameTd = () => {
        if(!this.props.agentSettings){
            console.error('Agent settings not found');
            console.log(this.props);
            return null;
        }
		let style = {
			width: default_first_column_width,
			minWidth: default_first_column_width,
			fontWeight: 700,
			backgroundColor: this.props.agentSettings.color,
            textAlign: 'left',
            color: '#000000',
            fontFamily: 'Arial',
            fontSize: '10pt',
            verticalAlign: 'bottom',
            whiteSpace: 'nowrap',
            direction: 'ltr',
		};
		let name = this.props.name.match(/(.*?) .*/)[1];
		if (this.props.agentSettings.showSurname) {
			name = this.props.name.match(/(.*? [a-zA-ZŠ]).*/)
				? `${this.props.name.match(/(.*? [a-zA-ZŠ]).*/)[1]}.`
				: this.props.name;
		}

		return (
			<td
				key={this.props.name + 0}
				className="name select border-top border-right "
				style={style}
				key={this.props.agentSettings.pos}
				colSpan={1}
			>
				&nbsp; {name}
			</td>
		);
	};
    get_lang_td = () => {
        // Ensure that the prop for agentSettings is not null
        if (!this.props.agentSettings)
            return this.getNameTd();

        // Get the language
        const {lang} = this.props.agentSettings;

        // Join the language together separated by a comma
        const langString = lang.join(', ');

        // Return the td, with the langString as upper case
        return (
            <td
                key={this.props.name + 0}
                className="name select border-right border-bottom"
                style={{
                    width: default_first_column_width,
                    minWidth: default_first_column_width,
                    fontWeight: 700,
                    backgroundColor: this.props.agentSettings.color,
                    textAlign: 'left',
                    color: '#000000',
                    fontFamily: 'Arial',
                    fontSize: '10pt',
                    verticalAlign: 'bottom',
                    whiteSpace: 'nowrap',
                    direction: 'ltr',
                }}
                key={this.props.agentSettings.pos}
                colSpan={1}
            >
                &nbsp; {langString.toUpperCase()}
            </td>
        );
    }


	hoursFromMinutes = (min) => {
		return `${Math.floor(min/60)}:${min%60}`
	}
	generateTr = () => {
		let userColumns = [];


		const colSpan = this.generateColspan(this.props.tableTask);
        const first_column_text =
            this.props.is_name ? this.getNameTd() : this.get_lang_td();
        userColumns.push(first_column_text);


		

		this.props.tableTask.forEach((details, index) => {
            const task = details.task;
			const onMouseDown = (event) => {
				if (event.button === 2 || event.ctrlKey || event.shiftKey || event.altKey || this.state.dragging || !task.working) return;
				store.dispatch({
					type: 'changeMainTask',
					payload: {
						currentColor: task.color,
						name: this.props.name,
						index,
						main: this.props.table,
					},
				});
				this.setState({ dragging: true });
				this.firstSelected = index;
                this.lastSelected = index;
			};

			const onMouseOver = () => {
				if (!task.working) return;
				if (this.state.dragging) {
					store.dispatch({
						type: 'changeMainTask',
						payload: {
							currentColor: task.color,
							name: this.props.name,
							index,
							main: this.props.table,
						},
					});
					this.lastSelected = index;
				}
			};

			const onClick = (event) => {
				if (event.ctrlKey) {
					store.dispatch({
						type: 'clearAll',
						payload: {
							name: this.props.name,
							main: this.props.table,
						},
					});
				}

				if (event.shiftKey) {
					store.dispatch({
						type: 'paintAdjacent',
						payload: {
							name: this.props.name,
							main: this.props.table,
							index
						},
					});
				}

				if (event.altKey) {
					store.dispatch({
						type: 'paintOpposite',
						payload: {
							name: this.props.name,
							selected: this.props.table,
							index
						},
					});
				}
			}

            let message = {};

            
			if ((this.props.textOverlay ) && !this.state.dragging && colSpan[index].text) {

				let textTarget = '';
				if (colSpan[index].text) {
					textTarget = colSpan[index].text;
					if(!this.props.exportedVersion){
						textTarget+= ` - (${this.hoursFromMinutes(colSpan[index].colspan * 30)})`
					}
					
				}

				message = {
					style: { width: textTarget !== '' ? colSpan[index].colspan * 35 : 0 , textAlign: "center", top: 5},
					text: textTarget,
				};
			}

			let totalTimeText = false;
			if(this.state.dragging && index === this.firstSelected){
				const {firstSelected, lastSelected} = this;
				const min = Math.min(firstSelected, lastSelected)
				const max = Math.max(firstSelected, lastSelected)
				const t = ((max - min) + 1) * 30;
				totalTimeText = this.hoursFromMinutes(t);
				message = {
					style: { width: 35 , textAlign: "center", top: 5},
					text: totalTimeText,
				}
			}

			let colSpanN = 1;
			let renderSpan = true;
			if(this.props.exportedVersion){
				colSpanN = colSpan[index].colspan;
				renderSpan = colSpan[index].render;
			}
            
			if(renderSpan){
				userColumns.push(
					<SingleTd
						onMouseDown={onMouseDown}
						onMouseOver={onMouseOver}
						onClick={onClick}
						key={this.props.name + (index + 1)}
						keyName={this.props.name + (index + 1)}
						style={{ backgroundColor: task.color }}
						extraClass={colSpan[index].uniqueClass}
						message={message}
						colSpan={colSpanN}
					/>,
				);
			}
			
		});

		return userColumns;
	};

	render () {
		return <tr>{this.generateTr()}</tr>;
	}
}

const mapStateToProps = (state, props) => {
	const agentSettings = state.present.agentSettings[props.name];
	const {  tools, displaySettings } = state.present;
	const { textOverlay } = state.present.displaySettings;
    const tableTask = state.present[props.table][props.name];
	const {exportedVersion} = displaySettings;
	return {
		agentSettings,
		tableTask,
		tools,
		textOverlay,
		exportedVersion
	};
};
export default connect(mapStateToProps)(SecondaryTr);

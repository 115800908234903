// React import
import React from 'react';
// Additional libraries

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { ActionCreators as UndoActionCreators } from 'redux-undo'


class ContextMenu extends React.Component {
	state = {
		xPos: '0px',
		yPos: '0px',
		showMenu: false,
	};

	componentDidMount () {
		document.addEventListener('keydown', this.handleKey);
		document.addEventListener('click', this.handleClick);
		document.addEventListener('contextmenu', this.handleContextMenu);
	}

	componentWillUnmount () {
		document.removeEventListener('keydown', this.handleKey);
		document.removeEventListener('click', this.handleClick);
		document.removeEventListener('contextmenu', this.handleContextMenu);
	}


	handleKey = (e) => {


		
		if (e.keyCode === 27) this.setState({ showMenu: false });
		
		if(e.keyCode === 90 && e.ctrlKey === true){
			store.dispatch({
				type: '@@redux-undo/UNDO'
			})
		}

		if(e.keyCode === 89 && e.ctrlKey === true){
			store.dispatch({
				type: '@@redux-undo/REDO'
			})
		}
        const keyMap = {
            "49": 'LIVECHAT',
            "97": 'LIVECHAT',
            "50": 'E-MAILS',
            "98": 'E-MAILS',
            "51": 'ASSIGNING',
            "99": 'ASSIGNING',
            "52": 'PHONES',
            "100": 'PHONES',
            "53": 'TRAINING',
            "101": 'TRAINING',
            "54": 'IN-TRAINING',
            "102": 'IN-TRAINING',
            "55": 'NEXUS',
            "103": 'NEXUS',
            "56": 'PRUSUKI',
            "104": 'PRUSUKI',
            "57": 'PRUSA KB',
            "105": 'PRUSA KB',
			"48": 'NONE',
            "96": 'NONE',
        }
        
		if(keyMap[''+e.keyCode]){
			this.setTool(keyMap[''+e.keyCode])
		}

	};
	handleClick = (e) => {
		// ...
		if (this.state.showMenu) this.setState({ showMenu: false });
	};

	handleContextMenu = (e) => {
		e.preventDefault();

		this.setState({
			xPos: `${e.pageX}px`,
			yPos: `${e.pageY}px`,
			showMenu: true,
		});

		// ...
	};

	setTool = (key) => {
        store.dispatch({
            type: 'hookActionUpdateObject',
            payload: [{
                address: `editSettings.selectedTool`,
                value: key
            }]
        })
    };

	generateLi = ({ tools }) => {
		return Object.keys(tools).map((k, index) => {
			let s = {};
			// if(this.props.getSelectedTool().task === this.props.toolList[k].task){
			//     s.backgroundColor = 'rgb(177 182 206)';
			// }
			return (
				<li
					style={s}
					onClick={() => {
						this.setTool(k);
					}}
					key={k}
				>
					<span className="a" style={{ backgroundColor: tools[k][0].color }} />
					<span className="b">{tools[k][0].task} {tools[k][0].key && <> ({tools[k][0].key})</>}</span>
				</li>
			);
		});
	};
	render () {
		const { showMenu, xPos, yPos } = this.state;

		if (showMenu)
			return (
				<ul
					className="menu"
					style={{
						top: yPos,
						left: xPos,
						position: 'absolute',
					}}
				>
					{this.generateLi(this.props)}
				</ul>
			);
		else return null;
	}
}

const mapStateToProps = (state) => {
	const { tools } = state.present;

	return {
		tools,
	};
};

export default connect(mapStateToProps)(ContextMenu);

// React import 
import React from 'react';
// Additional libraries 

import { connect } from 'react-redux';

import MainTr from './agent/MainTr';
class Body extends React.Component{

    generateAgentTr = ({agentShift}) => {
        const keys = Object.keys(agentShift);
        return keys.map((k,ind) => {
            return (
                <React.Fragment key={k}>
                    <MainTr 
                        key={k}
                        name={k}
                        table="agentTableMain"
                        is_name={true}
                    />
                    <MainTr 
                         key={k+'secondary'}
                         name={k}
                         table="agentTableSecondary"
                         is_name={false}
                    />
                </React.Fragment>
            )
        })
    }
    
    render(){
        return (
            <>
                { this.generateAgentTr(this.props) }
            </>
        )
    }

}

const mapStateToProps = (state) => {
    const {agentShift} = state.present ;
    return {
        agentShift
    }
}
export default connect(mapStateToProps)(Body)
// React import
import React from 'react';



import { connect } from 'react-redux';
import store from './../../../redux/store';
class Tools extends React.Component {
	generateTools = () => {
		const toolInfo = Object.keys(this.props.tools)
			.map((k) => {
				return {
					backgroundColor: this.props.tools[k][0].color,
					text: this.props.tools[k][0].text,
					key: this.props.tools[k][0].key,
					k
				};
			})
			.filter((e) => typeof e.key !== 'undefined');

		return toolInfo.map((t, index) => {
			const onClick = () => {
				store.dispatch({
					type: 'hookActionUpdateObject',
					payload: [{
						address: `editSettings.selectedTool`,
						value: t.k
					}]
				})
			}
			return (
				<div onClick={onClick} key={index} style={{ backgroundColor: t.backgroundColor, cursor: 'pointer'}}>
					{' '}
					{t.text} [{t.key}]{' '}
				</div>
			);
		});
	};

	render () {
		return <div className="tools-grid">{this.generateTools()}</div>;
	}
}

const mapStateToProps = (state) => {
	const { tools } = state.present;
	return {
		tools,
	};
};
export default connect(mapStateToProps)(Tools);

import React from "react";
import "./Grid.scss";

class Grid extends React.Component {

    render() {
        return(
            <div/>
        )
    }
}

export default Grid;
// React import 
import React from 'react'
// Additional libraries 

import { connect } from 'react-redux';

function SelectedTool ({toolDetails}){
        return (
            <div className="nav-color-indicator">
                <span className="color-show" style={{ backgroundColor: toolDetails[0].color }} />
                <span className="text-white text-right inline-block fix-width-175">{toolDetails[0].task}</span>
            </div>
        )
}

const mapStateToProps = (state) => {
    const {tools, editSettings} = state.present ;
    const { selectedTool } = editSettings;
    const toolDetails = tools[selectedTool]
    return {
        toolDetails
    }
}
export default connect(mapStateToProps)(SelectedTool)
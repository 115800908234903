// React import 
import React from 'react';
// Additional libraries 

import { connect } from 'react-redux';
import store from './../../../redux/store';
import Header from './Header'
import Body from './Body'

class Table extends React.Component{

    shouldComponentUpdate = (nextProps) => {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props);
    }
    render(){
        let tableStyle = {};
        let className = "mtop";
        if(this.props.exportedVersion){
            className = ""
        }
        return (
            <table className={className} style={tableStyle}>
                <thead>
                    {/* <Header /> */}
                </thead>
                <tbody>
                    <Body />
                </tbody>
            </table>
        )
    }

}

const mapStateToProps = (state, props) => {
    const {  displaySettings } = state.present;
    const {exportedVersion} = displaySettings;
    return {
        exportedVersion
    }
}
export default connect(mapStateToProps)(Table)
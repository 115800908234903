// React import
import React from 'react';
// Additional libraries
import 'bootstrap/dist/css/bootstrap.min.css';
import './gridModule.scss';

import axios from 'axios';

import NavBar from './navbar/NavBar';
import Table from './table/Table';
import ContextMenu from './context_menu/ContextMenu';

import store from './../../redux/store';

const _ = require('lodash');

class Grid extends React.Component {
	state = {
		loading: true,
        user_settings_loaded: false,
        error: false,
        error_message: '',
	};

	componentDidMount () {
		let self = this;

		console.log('mount');
		const url = `/api/schedule?add=${this.props.days}`;
        const user_settings_url = `/api/userSetting`;
        console.log(this.props)


        /**
         * Load the Schedule for the agents based on the given date
         */
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                store.dispatch({
                    type: 'loadAgentSchedule',
                    payload: {
                        agents: data
                    }
                });
            })
            .catch((error) => {
                console.error('Error:', error);
                self.setState({
                    error: true,
                    error_message: 'Unable to load the google sheet schedule'
                });

            })
            .finally(() => {
                this.setState({loading: false});
            });

        /**
         * Load the user settings
         */
        fetch(user_settings_url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                store.dispatch({
                    type: 'loadUserSettings',
                    payload: {
                        userSettings: data
                    }
                });
                /** Update the user_settings_loaded state */
                this.setState({
                    ...this.state,
                    user_settings_loaded: true
                });
            })
            .catch((error) => {
                console.error('Error:', error);
                self.setState({
                    error: true,
                    error_message: 'Unable to load the user settings, this is probably due to a network issue, contact @ithan for more information'
                });
            })
    }



    render () {

		return (
			<>
				{!this.exported && <NavBar />}
                { this.state.loading &&
                    <div className="text-center" style={{marginTop: 150}}>
                        <span className="sr-only">Loading...</span>
                        <div className="spinner-border text-primary" role="status">
                        </div>
                    </div>
                }
                { this.state.error && (
                    <div className="alert alert-danger" role="alert">
                        {this.state.error_message}
                     </div>)
                }
                { !this.state.loading && !this.state.error && this.state.user_settings_loaded && (
                    <Table />
                )}


			</>
		);
	}
}

export default Grid;

import chatTimes from './../../models/chatTimes';
import tools from './../../models/tools';
import { AgentUtils, Utils } from './userGeneration';
import hours from './../../Component/grid/Hours';
import undoable from 'redux-undo';
import u from 'updeep';
import agentSettings from './../../models/agentSettings';
const _ = require('lodash');

const context = {
	chatTimes,
	chatTimesSelectedIndex: 'weekdays',
	filters: {},
	tools: tools,
	hours: hours,
	generationSettings: {
		assignTargetTimeDirectly: false,
		useMaxChatTime: false,
		ignoreChatLimits: false,
	},
	weights: {
		distanceToLiveChatTarget: 2,
		languages: 0.3,
		agentChatFactor: 1.3,
	},
	displaySettings: {
		isDebug: false,
		exportedVersion: false,
		textOverlay: true,
		navbar: {
			chatAgents: true,
			czAgents: true,
			minAgents: true,
			maxAgents: true
		}
	},
	editSettings: {
		updateChatTargetInRealTime: false,
		selectedTool: 'LIVECHAT',
	},
	targetAgentsInChatByHour: [],
	agentSettings: agentSettings,
	agentShift: {},
	agentTableMain: {},
	agentsInChats: {
		all: hours.map((h,index) => []),
		cz: hours.map((h,index) => [])
	},
	agentTableSecondary: {},
	listOfAvailableTask: [],
	indexOfSelectedTask: false,
};

const reduce = (state = context, action) => {
	switch (action.type) {
        case 'loadUserSettings': {
            const {userSettings} = action.payload;
            // return state;
            return u({
                agentSettings: userSettings
            }, state);
        }
		case 'hookActionUpdateObject': {
			let updatedProperty = {};
			if (Array.isArray(action.payload)) {
				action.payload.forEach((item) => {
					let previousKeys = [];
					let address = item.address.split('.');
					address.forEach((propertyName, index) => {
						let current = updatedProperty;
						previousKeys.map((k) => (current = current[k]));
						current[propertyName] =
							index === address.length - 1 ? item.value : { ...current[propertyName] };
						previousKeys.push(propertyName);
					});
				});
			}
			else {
				let previousKeys = [];
				let address = action.payload.address.split('.');
				address.forEach((propertyName, index) => {
					let current = updatedProperty;
					previousKeys.map((k) => (current = current[k]));
					current[propertyName] = index === address.length - 1 ? action.payload.value : {};
					previousKeys.push(propertyName);
				});
			}
			return u(updatedProperty, state);
		}
		case 'loadAgentSchedule': {
			let { agents } = _.cloneDeep(action.payload);
			console.log(agents);
			let agentTableMain = {};
			let agentTableSecondary = {};
			for (let key in agents) {
				agentTableMain[key] = AgentUtils.fillChatHours({
					shift: agents[key],
					hours: hours,
				});
				agentTableSecondary[key] = _.cloneDeep(agentTableMain[key]);
			}
			return u(
				{
					agentShift: agents,
					agentTableMain,
					agentTableSecondary,
				},
				state,
			);
		}

		case 'changeMainTask': {
			const { currentColor, name, index, main } = action.payload;
			if (state.tools[state.editSettings.selectedTool][0].color === currentColor) {
				return state;
			}
			else {
				let taskClone = _.cloneDeep(state[main][name]);
				let agentTableSecondaryClone = _.cloneDeep(state['agentTableSecondary'][name]);
				let allAgentsInChats = _.cloneDeep(state.agentsInChats.all);

				taskClone[index]['task']['color'] = state.tools[state.editSettings.selectedTool][0].color;
				taskClone[index]['task']['task'] = state.tools[state.editSettings.selectedTool][0].task;
				taskClone[index]['task']['text'] = state.tools[state.editSettings.selectedTool][0].text || '';

				if(taskClone['agentTableMain']['task']['task'] === 'chats'){
					// Tools.
				}

				if (
					main !== 'agentTableSecondary' &&
					state.tools[state.editSettings.selectedTool][1] !== false &&
					state.tools[state.editSettings.selectedTool][1].color !==
						agentTableSecondaryClone[index]['task']['color'] &&
					agentTableSecondaryClone[index]['task']['color'] === '#fff'
				) {
					agentTableSecondaryClone[index]['task']['color'] =
						state.tools[state.editSettings.selectedTool][1].color;
					agentTableSecondaryClone[index]['task']['task'] =
						state.tools[state.editSettings.selectedTool][1].task;
					agentTableSecondaryClone[index]['task']['text'] =
						state.tools[state.editSettings.selectedTool][1].text || '';
					return u(
						{
							[main]: {
								[name]: taskClone,
							},
							agentTableSecondary: {
								[name]: agentTableSecondaryClone,
							},
						},
						state,
					);
				}

				if(state.tools[state.editSettings.selectedTool][1].task === 'chats'){
					
				}
				return u(
					{
						[main]: {
							[name]: taskClone,
						},
					},
					state,
				);
			}
		}

		case 'updateMultipleTask': {
			const { min, max, name, main } = action.payload;
			let taskClone = _.cloneDeep(state[main][name]);
			let agentTableSecondaryClone = _.cloneDeep(state['agentTableSecondary'][name]);

			taskClone.forEach((task, index) => {
				if (!(index >= min && index <= max)) return;
				if (state.tools[state.editSettings.selectedTool][0].color === task['task']['color']) return;

				task['task']['color'] = state.tools[state.editSettings.selectedTool][0].color;
				task['task']['task'] = state.tools[state.editSettings.selectedTool][0].task;
				task['task']['text'] = state.tools[state.editSettings.selectedTool][0].text || '';

				if (
					main !== 'agentTableSecondary' &&
					state.tools[state.editSettings.selectedTool][1] !== false &&
					state.tools[state.editSettings.selectedTool][1].color !==
						agentTableSecondaryClone[index]['task']['color'] &&
					agentTableSecondaryClone[index]['task']['color'] === '#fff'
				) {
					agentTableSecondaryClone[index]['task']['color'] =
						state.tools[state.editSettings.selectedTool][1].color;
					agentTableSecondaryClone[index]['task']['task'] =
						state.tools[state.editSettings.selectedTool][1].task;
					agentTableSecondaryClone[index]['task']['text'] =
						state.tools[state.editSettings.selectedTool][1].text || '';
				}
			});

			if (main !== 'agentTableSecondary') {
				return u(
					{
						[main]: {
							[name]: taskClone,
						},
						agentTableSecondary: {
							[name]: agentTableSecondaryClone,
						},
					},
					state,
				);
			}
			return u(
				{
					[main]: {
						[name]: taskClone,
					},
				},
				state,
			);
		}

		case 'clearAll': {
			const { name, main } = action.payload;
			let taskClone = _.cloneDeep(state[main][name]);

			taskClone.forEach((task, index) => {
				if (!task['task'].working) return;

				task['task']['color'] = state.tools[state.editSettings.selectedTool][0].color;
				task['task']['task'] = state.tools[state.editSettings.selectedTool][0].task;
				task['task']['text'] = state.tools[state.editSettings.selectedTool][0].text || '';
			});

			return u(
				{
					[main]: {
						[name]: taskClone,
					}
				},
				state,
			);
		}

		case 'paintAdjacent': {
			const { name, main, index } = action.payload;
			let taskClone = _.cloneDeep(state[main][name]);
			let agentTableSecondaryClone = _.cloneDeep(state['agentTableSecondary'][name]);

			if (main !== 'agentTableSecondary' && state.tools[state.editSettings.selectedTool][1] !== false) {
				taskClone = AgentUtils.paintAllAdjacentTask(
					taskClone,
					index,
					state.tools[state.editSettings.selectedTool],
					agentTableSecondaryClone,
				);
				return u(
					{
						[main]: {
							[name]: taskClone.taskList,
						},
						'agentTableSecondary': {
							[name]: taskClone.secondaryTask,
						},
					},
					state,
				);
			}
			else {
				taskClone = AgentUtils.paintAllAdjacentTask(
					taskClone,
					index,
					state.tools[state.editSettings.selectedTool]
				);
				return u(
					{
						[main]: {
							[name]: taskClone.taskList,
						},
					},
					state,
				);
			}

			
		}
		case 'paintOpposite': {
			const { name, selected ,index} = action.payload;
			let agentTableMainClone = _.cloneDeep(state['agentTableMain'][name]);
			let agentTableSecondaryClone = _.cloneDeep(state['agentTableSecondary'][name]);

			let taskClone = AgentUtils.paintAllOppositeAdjacentTask(
				agentTableMainClone,
				agentTableSecondaryClone,
				selected, 
				index,
				state.tools[state.editSettings.selectedTool]
			);

			return u(
				{
					'agentTableMain': {
						[name]: taskClone.agentTableMain,
					},
					'agentTableSecondary': {
						[name]: taskClone.secondaryTask,
					},
				},
				state,
			);
			

			
		}

		case 'updateUserTask':
			// Make a new object for task
			let { name, index, main, color } = action.payload;
			let taskClone = _.cloneDeep(state.agentTable[name]);
			taskClone[index]['task'][main]['color'] = color;

			return u(
				{
					agentTable: {
						[name]: taskClone,
					},
				},
				state,
			);
		default:
			return state;
	}
};

const undoableRedecers = undoable(reduce);
export default undoableRedecers;
